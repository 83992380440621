
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { LoginMixin } from './mixin'
import LanguageBox from '@/components/dialogs/lang-dialog-view.vue'

@Options({
    components: {
        LanguageBox
    }
})
export default class PcLogin extends Mixins(LoginMixin) {
    showLangbox = false
    mounted(): void {
        //
    }
}
