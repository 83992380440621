
// import { Dialog, EventBus, EventBusEvents } from '@/event/EventBus'
import { AppMutation } from '@/store/module/app'
import { Device } from '@/store/types'
import { Lang } from '@/utils/constants'
import { Options, Vue } from 'vue-class-component'
import { useI18n } from 'vue-i18n'
import { Action, Getter, Mutation } from 'vuex-class'

@Options({})
export default class CustomMoneyDialogView extends Vue {
    iN = useI18n().t
    locale = useI18n().locale

    @Getter('device') device!: Device
    @Getter('lang') lang!: string

    @Action('setCustomChip') setCustomChip!: (value: number) => void
    @Mutation(AppMutation.SET_LANG) setLang!: (value: string) => void

    selectedLang = ''
    langs = Lang

    beforeMount() {
        this.selectedLang = this.lang
    }

    closeDialog(): void {
        // EventBus.$emit(Dialog.CUSTOMMONEY, false)
        // EventBus.$emit(EventBusEvents.SHOW_DIALOGS, Dialog.CUSTOMMONEY)

        this.$emit('on-close')
        // console.log(this.lang)
    }

    confirm(): void {
        this.setLang(this.selectedLang)
        this.locale = this.selectedLang
        this.$emit('on-close')
    }
}
