import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class LoginParam extends BaseParams {
    username: string
    pwd: string

    constructor(username: string, pwd: string) {
        super(ACT.LOGIN)
        this.username = username
        this.pwd = pwd
    }
}
