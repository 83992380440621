
import { Device } from '@/store/types'
import { Vue, Options } from 'vue-class-component'
import { Getter } from 'vuex-class'
import MobileLogin from './m-login.vue'
import PcLogin from './d-login.vue'

@Options({
    components: {
        MobileLogin,
        PcLogin
    }
})
export default class Login extends Vue {
    //#region Vuex
    @Getter('device') device!: Device

    //#endregion
    //#region Vue Methods
    //#endregion
}
